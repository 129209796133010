<template>

    <form class="space-y-6 w-full">

        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 w-full">
            <div class="col-span-1">
                <Select v-model="form.supplier" :items="catalogItem.available_suppliers" :errors="errors.supplier"
                        placeholder="Piegādātrājs"/>
            </div>

            <div class="col-span-1">
                <Input name="external_id" placeholder="Piegādātāja kods" v-model="form.external_id"
                       :errors="errors.external_id ? errors.external_id : externalIdError"/>
            </div>
        </div>

        <div class="md:flex-shrink-0 flex items-center flex-wrap ml-auto gap-3 justify-end pt-4">
            <template v-if="!loading">
                <button type="button"
                        class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out"
                        @click.prevent="submit">
                    Saglabāt
                </button>
            </template>
            <template v-else>
                <Loading/>
            </template>

            <button @click.prevent="closeForm" type="button"
                    class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0">
                Atcelt
            </button>

        </div>

    </form>

</template>

<script>
import {mapGetters} from "vuex";
import Select from "@/components/Components/Select";
import Input from "@/components/Components/Input";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";

export default {
    name: "AddCatalogItemSupplier",
    components: {
        Select,
        Input,
        Loading,
    },
    props: {
        catalogItem: {
            type: Object,
            required: true,
        }
    },
    data: () => ({
        externalIdError: null,
        form: {
            supplier: null,
            external_id: null,
        },
    }),
    computed: {
        ...mapGetters({
            loading: "loading",
            errors: 'errors',
        }),
        formValidation() {
            return {
                supplier: {
                    rules: ['required']
                },
                external_id: {
                    rules: ['required']
                },
            }
        },
    },
    methods: {
        closeForm() {
            this.$store.dispatch('removeAllFormsForDisplay')
        },
        submit() {
            this.externalIdError = null
            this.$Progress.start()
            if (Validation(this.formValidation, this.form)) {
                this.$store.dispatch("createNewCatalogItemSupplier", {
                    data: {
                        supplier_id: this.form.supplier.id,
                        external_id: this.form.external_id
                    },
                    catalog_item_id: this.catalogItem.id,
                }).catch(error => {
                    if(error.response.data.errors.external_id) {
                        this.externalIdError = error.response.data.errors.external_id
                    }
                });
            } else this.$Progress.fail()
        }
    },
}
</script>