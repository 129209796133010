<template>
    <template v-if="editableItem.id !== supplier.id">
        <div class="flex w-full">
            <div class="flex w-full">
                <ItemText title="Piegādātājs" :text="supplier.supplier.name" />
                <ItemText title="Kods" :text="supplier.external_id" />
            </div>

            <div class="flex ml-auto">
                <Button icon="pencil" @click="setEditItem(supplier)" />
            </div>
            <div class="flex ml-auto">
                <Button icon="delete" @click="deleteSupplier" />
            </div>
        </div>
    </template>

    <template v-if="editableItem.id === supplier.id">
        <EditCatalogItemSupplier :supplier="supplier" :catalogItem="catalogItem" />
    </template>
</template>

<script>
import {mapGetters} from "vuex"

import ItemText from "@/components/Components/ItemText"
import EditCatalogItemSupplier from "@/components/Settings/Catalog/CatalogItem/Suppliers/EditCatalogItemSupplier"

export default {
    name: "SingleCatalogItemSupplier",
    components: {
        ItemText,
        EditCatalogItemSupplier,
    },
    props: {
        supplier: {
            type: Object,
            required: true,
        },
        catalogItem: {
            type: Object,
            required: true,
        }
    },
    computed: {
        ...mapGetters({
            editableItem: 'editableItem'
        })
    },
    methods: {
        setEditItem(item) {
            this.$store.dispatch('setEditableItem', item)
        },
        deleteSupplier() {
            if(confirm('Vai esi drošs?')){
                this.$Progress.start()

                this.$store.dispatch('deleteCatalogItemSupplier', {
                    id: this.supplier.id,
                    catalog_item_id: this.catalogItem.id
                })
                .then(() => this.$Progress.finish())
                .catch(() => this.$Progress.fail())
            }
        },
    }
}
</script>

<style>

</style>