<template>
    <item-card>
        <template v-slot:title>
            <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                Piegādātāji
            </h3>
        </template>

        <template v-slot:buttons>
            <div class="flex gap-3">
                <template v-if="catalogItem.available_suppliers.length">
                <Button icon="plus" @click="showForm('AddCatalogItemSupplier')" />
                </template>
            </div>
        </template>

        <template v-slot:content>

            <template v-if="!displayForm('AddCatalogItemSupplier')">
                <template v-for="supplier in catalogItem.supplier_catalog_items" :key="supplier.id">
                    <SingleCatalogItemSupplier :supplier="supplier" :catalogItem="catalogItem" />
                </template>
            </template>

            <template v-if="displayForm('AddCatalogItemSupplier')">
                <AddCatalogItemSupplier :catalogItem="catalogItem" />
            </template>

        </template>
    </item-card>
</template>

<script>
import {mapGetters} from "vuex";
import ItemCard from "@/components/Components/ItemCard"
import SingleCatalogItemSupplier from "@/components/Settings/Catalog/CatalogItem/Suppliers/SingleCatalogItemSupplier"
import AddCatalogItemSupplier from "@/components/Settings/Catalog/CatalogItem/Suppliers/AddCatalogItemSupplier"

export default {
    name: "CatalogItemSuppliers",
    components: {
        ItemCard,
        SingleCatalogItemSupplier,
        AddCatalogItemSupplier,
    },
    props: {
        catalogItem: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            formsForDisplay: 'formsForDisplay',
        }),
    },
    methods: {
        showForm(formName){
            this.$store.dispatch('addFormForDisplay', formName)
        },
        displayForm(formName){
            return this.formsForDisplay.includes(formName)
        },
    }
}
</script>

<style>

</style>e